import {BatchHeader, BatchTitle, BatchImage} from "./framer"
import { generateCompositionBlock } from "./helpers"
import styles from './batches.module.css'

import { Spacer, Grid } from "@geist-ui/core"
import Skeleton from "react-loading-skeleton"

import BatchChart from "./chart"
import { Card } from "../../ui"

const BatchBreakdown = ({ batch, height }) => {
    return <Grid.Container style={{ width: "100%", borderRadius: "40px", overflow: "hidden" }}>
        {Object.keys(batch?.composition ?? {}).map((strain, index) => {
            const width = (batch.composition[strain].plants / batch.plants.length) * 24
            return <Grid key={strain} xs={width} style={{ height: height ?? "20px", background: batch.composition[strain].colour ?? "rgba(0, 0, 0, 0." + (2 + index) + ")" }}>
            </Grid>
        })}
        <Grid></Grid>
    </Grid.Container>
}

const BatchItem = ({ batch, selected, variant, tap }) => {
    return <Grid.Container onClick={tap} justify="space-between" alignItems='center' style={{ padding: "20px" }}>
        <Grid>
            <Grid.Container gap={1} alignItems='center'>
                <Grid>
                    <BatchImage selected={selected} variant={variant} image={batch.image}></BatchImage>
                </Grid>
                <Grid>
                    <BatchBreakdown height='5px' batch={batch} />
                    <h5 className={styles.title}>{batch.name ?? "Untitled Batch"}</h5>
                    <span className={styles.subtitle}>{batch.type === 'mixed' ? "Mixed Batch" : "Single Origin Batch"}</span>
                </Grid>
            </Grid.Container>
        </Grid>
        <Grid style={{ opacity: 0.5 }}>
            {batch.plants.length} Plants
        </Grid>
    </Grid.Container>
}

const BatchCard = ({ batch, onClick }) => {

    if (!batch) return <Card style={{ padding: "40px", height: "100%", position: "relative", }} >
        <Grid.Container justify="space-between">
            <Grid>
                <Grid.Container gap={1} alignContent='center'>
                    <Grid>
                        <Skeleton style={{ borderRadius: "200px", width: "50px", height: "50px" }} />
                    </Grid>
                    <Grid>
                        <Skeleton width='50px' height={10} />
                        <h5 className={styles.title}><Skeleton width='100px' /></h5>
                        <span className={styles.subtitle}><Skeleton width='80px' /></span>
                    </Grid>
                </Grid.Container>
            </Grid>
            <Grid className={styles.subtitle}>
                <Skeleton width='40px' />
            </Grid>
        </Grid.Container>
        <p className={styles.composition}>
            <Skeleton count={2} />
        </p>
        <Spacer />
        <Skeleton style={{ height: "30px" }} />
    </Card>
    const compBlock = generateCompositionBlock(batch)
    return <Card onClick={onClick} style={{ padding: "20px", height: "100%", position: "relative", cursor: "pointer" }} >
        <Grid.Container justify="space-between">
            <Grid>
                <Grid.Container gap={0} alignContent='center'>
                    <Grid>
                        {batch.image ? <img height={60} width={60} src={batch.image} style={{ borderRadius: "200px" }} /> : ""}
                    </Grid>
                    <Grid>
                        <h5 className={styles.title}>{batch.name ?? "Untitled Batch"}</h5>
                        <span className={styles.subtitle}>{batch.type === 'mixed' ? "Mixed Batch" : "Single Origin Batch"}</span>
                    </Grid>
                </Grid.Container>
            </Grid>
            <Grid className={styles.subtitle}>
                {batch.plants.length} Plants
            </Grid>
        </Grid.Container>
        <p className={styles.composition}>
            {compBlock}
        </p>
        <Spacer />
        <BatchBreakdown batch={batch} />
    </Card>
}

export { BatchItem, BatchTitle, BatchHeader, BatchCard, BatchChart }