import React from "react"
import {PieChart, Pie, Cell, Tooltip} from "recharts"

const generateChartData = (composition) => {
    var results = []
    for (const key in composition) {
        results.push({
            name: composition[key].strainName,
            value: composition[key].plants,
            color: composition[key].colour
        })
    }
    return results
}


export default function BatchChart({ batch, width, height, animated=true }) {

    if (!batch) return <></>

    const { composition } = batch
    const data = generateChartData(composition)

    const finalWidth = width ?? 600
    const finalHeight = height ?? 400

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ WebkitBackdropFilter: "blur(20px)", backdropFilter: "blur(20px)", padding: "20px", background: "rgba(255, 255, 255, 0.8)", borderRadius: "20px", border: "1px solid rgba(0, 0, 0, 0.1)" }}>
                    <p className="label">{`${payload[0].name} : ${Math.round(payload[0].value / batch.plants.length * 100)}%`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <PieChart width={finalWidth} height={finalHeight} >
            <Pie
                isAnimationActive={animated}
                data={data}
                cx={finalWidth / 2}
                cy={finalHeight / 2}
                innerRadius={finalWidth / 6}
                outerRadius={finalHeight / 3.5}
                fill="#8884d8"
                dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </Pie><Tooltip content={<CustomTooltip />} />
        </PieChart>
    );
}
